import { Divider, Grid, Typography, Box, Hidden } from "@mui/material";
import { useEffect, useState } from "react";

import relatedQuestionsList from "../../data/related_question.json";
import SendRequestModal, {
  SendRequestButton,
} from "./SendRequestModal/SendRequestModal";

const FaqView = ({
  questionId,
  question,
  description,
  assosiatedQuestions,
  onQuestionClickHandler,
  type,
}) => {
  const linkStyle = {
    my: 1,
    cursor: "pointer",
    "&:hover": { bgcolor: "#FBE8EA" },
    p: 1,
    borderRadius: 2,
  };
  const [relatedQuestions, setRelatedQuestions] = useState([]);
  const [isRequestModalOpen, setRequestModalOpen] = useState(false);

  const handleRequestModalClose = () => {
    setRequestModalOpen(false);
  };
  const handleRequestModalOpen = () => {
    setRequestModalOpen(true);
  };

  useEffect(() => {
    if (type === "FAQ") {
      const temp = relatedQuestionsList.filter((related_question) =>
        related_question.question_ids.includes(parseInt(questionId))
      );
      setRelatedQuestions(temp);
    }
  }, [questionId, type]);

  useEffect(() => {
    const modalHeight = document.getElementById("modal-body").offsetHeight;
    document.getElementById("grid-container").style.height = `${
      modalHeight - 48
    }px`;
  });

  return (
    <Grid
      container
      spacing={1}
      py={5}
      mt={0}
      justifyContent="center"
      sx={{ overflowY: "scroll", height: "100%" }}
      height="100%"
      id="grid-container"
    >
      <Hidden mdDown>
        <Grid item md={1} />
        <Grid item md={2}>
          <Typography variant="h6" color="primary" fontWeight="bold">
            Articles in this section!
          </Typography>
          <Box>
            {assosiatedQuestions.map((faq) => (
              <Typography
                key={faq.id}
                variant="body2"
                textTransform="capitalize"
                component="div"
                sx={{
                  ...linkStyle,
                  bgcolor: `${
                    faq.id === questionId && type === "FAQ" ? "#FBE8EA" : ""
                  }`,
                }}
                onClick={() => onQuestionClickHandler(faq.id, "FAQ", false)}
              >
                <div dangerouslySetInnerHTML={{ __html: faq.question }}></div>
              </Typography>
            ))}
          </Box>
        </Grid>
      </Hidden>

      <Grid item xs={10} sm={10} md={6}>
        <Typography
          variant="h4"
          component="h4"
          fontWeight="bold"
          color="primary"
          textTransform="capitalize"
        >
          {question}
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          variant="body2"
          component="div"
          textAlign="justify"
        >
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Typography>
        <Divider sx={{ my: 3, height: 2 }} />
        <SendRequestButton closePopup={handleRequestModalOpen} />
      </Grid>

      {relatedQuestions.length > 0 ? (
        <Grid item xs={10} sm={10} md={2} sx={{ mt: { xs: 2, md: 1 } }}>
          <Typography variant="h6" color="primary" fontWeight="bold">
            Related Questions!
          </Typography>
          <Box sx={{ maxWidth: 200 }}>
            {relatedQuestions.map((faq) => (
              <Typography
                key={faq.id}
                variant="body2"
                component="div"
                textTransform="capitalize"
                sx={{
                  ...linkStyle,
                  bgcolor: `${
                    faq.id === questionId && type === "RELATED" ? "#FBE8EA" : ""
                  }`,
                }}
                onClick={() => onQuestionClickHandler(faq.id, "RELATED")}
              >
                <div dangerouslySetInnerHTML={{ __html: faq.question }}></div>
              </Typography>
            ))}
          </Box>
        </Grid>
      ) : (
        <Grid item xs={10} sm={10} md={2} sx={{ mt: { xs: 2, md: 1 } }}></Grid>
      )}

      <Hidden mdDown>
        <Grid item md={1} />
      </Hidden>
      <SendRequestModal
        isRequestModalOpen={isRequestModalOpen}
        handleRequestModalClose={handleRequestModalClose}
      />
    </Grid>
  );
};

export default FaqView;
