import React from "react";
import {
  Typography,
  Toolbar,
  InputBase,
  InputAdornment,
  IconButton,
  Grid,
  Hidden,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import FAQ from "../../data/faq.json";

const ModalHeader = ({ handleClose, handleOpen, id, title, question }) => {
  const [searchValue, setSearchValue] = React.useState({ id: 1, label: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleOpen(searchValue.id, "FAQ");
  };

  return (
    <Toolbar
      sx={{
        borderBottom: "1px solid #ffcccc",
        minHeight: "48px !important",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item md={1}></Grid>
        <Grid item sx={{ flex: 1, display: "flex", alignItems: "center" }}>
          <Typography
            color=""
            fontSize="small"
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
            onClick={handleClose}
          >
            FAQ
          </Typography>
          <ArrowRightIcon fontSize="small" />
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
            fontSize="small"
            onClick={() => handleOpen(id, "SECTION")}
          >
            {title}
          </Typography>
          {question ? (
            <>
              <ArrowRightIcon fontSize="small" />
              <Typography fontSize="small" color="primary">
                {question}
              </Typography>
            </>
          ) : null}
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <form sx={{ display: "flex" }} onSubmit={handleSubmit}>
              <Autocomplete
                disablePortal
                sx={{ width: "100%" }}
                options={FAQ.map((obj) => {
                  return {
                    id: obj.id,
                    label: obj.question,
                  };
                })}
                freeSolo={true}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <InputBase
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    className="header-search"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          sx={{
                            height: "30px",
                            width: "30px",
                            boxShadow: "0 !important",
                          }}
                          variant="contained"
                          color="primary"
                        >
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                value={searchValue}
                onChange={(event, value, reason) => {
                  if (reason === "selectOption") {
                    setSearchValue(value);
                  }
                }}
                autoComplete={searchValue.length >= 3 ? true : false}
              />
            </form>
          </Grid>
          <Grid item md={1}></Grid>
        </Hidden>
      </Grid>
    </Toolbar>
  );
};

export default ModalHeader;
