import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";

const SectionView = ({ title, questionList, onQuestionClickHandler }) => {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={10} sm={8} md={6} mt={5} lg={4}>
        <Typography
          variant="h4"
          component="h4"
          fontWeight="bold"
          color="primary"
        >
          {title}
        </Typography>
        <List sx={{ width: "100%", maxWidth: 500 }}>
          {questionList.map((faq) => (
            <ListItem
              onClick={() => onQuestionClickHandler(faq.id, "FAQ")}
              disableGutters
              key={faq.id}
              sx={{
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              <ListItemText>
                {faq.question.charAt(0).toUpperCase() + faq.question.slice(1)}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default SectionView;
