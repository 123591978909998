import { Box, Typography } from "@mui/material";
import React from "react";
import CustomText from "./CustomText";

const MainLayout = ({ handleOpen }) => {
  const flexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };
  return (
    <Box sx={flexStyle}>
      <Typography
        variant="h4"
        component="h4"
        marginTop="100px"
        fontWeight="bold"
        color="white"
      >
        <Typography
          color="primary"
          variant="h4"
          component="span"
          fontWeight="bold"
        >
          Hi!
        </Typography>
        &nbsp;How can we help you?
      </Typography>
      <CustomText handleOpen={handleOpen} />
    </Box>
  );
};

export default MainLayout;
