import { Grid } from "@mui/material";
import React from "react";
import { sectionList } from "../../data/sectionList";
import CustomCard from "./CustomCard";

const CardLayout = ({ handleOpen }) => {
  return (
    <Grid
      container
      spacing={5}
      mt={1}
      sx={{
        justifyContent: "center",
      }}
    >
      {sectionList.map((card) => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={card.id}>
          <CustomCard
            id={card.id}
            icon={card.icon}
            title={card.title}
            handleOpen={handleOpen}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CardLayout;
