import LiveHelpTwoToneIcon from "@mui/icons-material/LiveHelpTwoTone";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import SecurityTwoToneIcon from "@mui/icons-material/SecurityTwoTone";

export const sectionList = [
  {
    id: 1,
    icon: <LiveHelpTwoToneIcon color="primary" sx={{ fontSize: "64px" }} />,
    title: "General Queries",
  },
  {
    id: 2,
    icon: (
      <AccountBalanceTwoToneIcon color="primary" sx={{ fontSize: "64px" }} />
    ),
    title: "Deposit and Withdraw Queries",
  },
  {
    id: 3,
    icon: (
      <ManageAccountsTwoToneIcon color="primary" sx={{ fontSize: "64px" }} />
    ),
    title: "Account related Queries",
  },
  {
    id: 4,
    icon: <SupportAgentRoundedIcon color="primary" sx={{ fontSize: "64px" }} />,
    title: "KYC related Queries",
  },
  {
    id: 5,
    icon: <SecurityTwoToneIcon color="primary" sx={{ fontSize: "64px" }} />,
    title: "Security related Queries",
  },
];
