import React, { useState } from "react";
import { Button, Grid, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import FAQ from "../data/faq.json";

const CustomText = ({ handleOpen }) => {
  const [searchValue, setSearchValue] = useState();
  return (
    <Grid container sx={{ mt: 5, justifyContent: "center" }}>
      <Grid
        item
        sx={{ display: "flex", maxWidth: 300 }}
        xs={12}
        sm={10}
        md={6}
        lg={4}
        xl={3}
      >
        <Autocomplete
          sx={{ flex: 1 }}
          disablePortal
          id="combo-box-demo"
          options={FAQ.map((obj) => {
            return {
              id: obj.id,
              label: obj.question,
            };
          })}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className="search"
              placeholder="Search..."
            />
          )}
          value={searchValue}
          onChange={(event, value, reason) => {
            if (reason === "selectOption") {
              setSearchValue(value);
            }
          }}
        />

        <Button
          onClick={() => {
            handleOpen(searchValue.id, "FAQ");
          }}
          sx={{
            height: "30px",
            minWidth: "40px",
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            boxShadow: "0 !important",
          }}
          variant="contained"
          color="primary"
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomText;
