import React, { useEffect, useState } from "react";
import { Modal, Slide } from "@mui/material";
import { Box } from "@mui/system";

import Bg from "../../assest/img/bg-design.png";
import SectionView from "./SectionView";
import FaqView from "./FaqView";
import ModalHeader from "./ModalHeader";

const style = {
  position: "absolute",
  bottom: 0,
  outline: 0,
  width: "100vw",
  height: "85vh",
  background: "url(" + Bg + ") no-repeat center center fixed",
  bgcolor: "background.paper",
  border: "0",
  boxShadow: 24,
};

const CustomModal = ({
  open = "false",
  handleClose,
  handleOpen,
  modalData,
  modalType,
}) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("FAQ");

  useEffect(() => {
    setData(modalData);
    setType(modalType);
  }, [modalData, modalType]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: 0 }}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box sx={style} id="modal-body">
          <ModalHeader
            handleClose={handleClose}
            handleOpen={handleOpen}
            id={data.sectionId || data.id}
            title={data.section || data.title}
            question={data.question}
          />
          {type === "FAQ" || type === "RELATED" ? (
            <FaqView
              questionId={data.id}
              type={type}
              question={data.question}
              description={data.description}
              assosiatedQuestions={data.assosiatedQuestions}
              onQuestionClickHandler={handleOpen}
            />
          ) : (
            <SectionView
              sectionId={data.id}
              title={data.title}
              questionList={data.questionList}
              onQuestionClickHandler={handleOpen}
            />
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default CustomModal;
