import React, { useState } from "react";
import { Box } from "@mui/material";
import MainLayout from "./component/MainLayout";
import Navbar from "./component/Navbar";
import CardLayout from "./component/Card/CardLayout";
import CustomModal from "./component/Modal/CustomModal";
import FAQ from "./data/faq.json";
import relatedQuestionsList from "./data/related_question.json";
import "./App.css";
import { sectionList } from "./data/sectionList";

function App() {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({ type: "FAQ" });

  const openHandler = (id, type, isOpen = true) => {
    if (type === "FAQ" || type === "RELATED") {
      let obj = [],
        sectionDetails = [],
        assosiatedQuestions = [];

      if (type === "FAQ") {
        // Find Selected Question from FAQ.json
        obj = FAQ.find((o) => o.id === id);
        // Find Selected Question's Section Details
        sectionDetails = sectionList.find(
          (section) => section.id === obj.sectionId
        );
        // Choose 3 random questions from the FAQ.json
        assosiatedQuestions = FAQ.filter(
          (faq) => faq.sectionId === sectionDetails.id
        );
      } else if (type === "RELATED") {
        // Find Selected Question from FAQ.json
        obj = relatedQuestionsList.find((o) => o.id === id);
        // Find Selected Question's Section Details
        sectionDetails = sectionList.find(
          (section) => section.id === obj.sectionId
        );
        // Choose 3 random questions from the FAQ.json
        assosiatedQuestions = FAQ.filter(
          (faq) => faq.sectionId === sectionDetails.id
        );
      }

      // Set Modal Data
      setModalData({
        ...obj,
        type,
        section: sectionDetails.title,
        assosiatedQuestions,
      });
    } else if (type === "SECTION") {
      // Find Selected Section Details
      const sectionDetails = sectionList.find((section) => section.id === id);
      const questionList = FAQ.filter((faq) => faq.sectionId === id);
      setModalData({
        ...sectionDetails,
        questionList,
        type,
      });
    }
    if (isOpen) {
      setOpen(true);
    }
  };
  const closeHandler = () => setOpen(false);

  return (
    <Box sx={{ px: 10 }}>
      {/* NavBar */}
      <Navbar />

      {/* // MainLayout */}
      <MainLayout handleOpen={openHandler} />

      {/* // cardLayout */}
      <CardLayout handleOpen={openHandler} />

      {/* Modal */}
      <CustomModal
        open={open}
        handleOpen={openHandler}
        handleClose={closeHandler}
        modalData={modalData}
        modalType={modalData.type}
      />
    </Box>
  );
}

export default App;
