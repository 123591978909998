import React from "react";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import ampiyLogo from "../assest/img/ampiy-nameplate.png";
import { Grid, Hidden, IconButton } from "@mui/material";
import { Facebook, Twitter } from "@mui/icons-material";
import instagramLogo from "../assest/logo/instagram.png";

const iconStyle = {
  width: "40px",
  height: "40px",
  ":hover": {
    background: "white",
  },
  marginX: 1,
};

const Navbar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar>
        <Grid container justifyContent="center" spacing={1}>
          <Hidden smDown>
            <Grid item xs={2} sm={4} />
          </Hidden>
          <Grid item xs={12} sm={4} textAlign="center">
            <img style={{ width: "150px" }} src={ampiyLogo} alt="logo" />
          </Grid>
          <Hidden smDown>
            <Grid item xs={2} sm={4} textAlign="right">
              <IconButton
                href="https://twitter.com/ampiyofficial"
                target="_blank"
                sx={iconStyle}
              >
                <Twitter sx={{ color: "#1DA1F2" }} />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/ampiyofficial"
                target="_blank"
                sx={iconStyle}
              >
                <Facebook sx={{ color: "#4267B2" }} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/ampiyofficial/"
                target="_blank"
                sx={iconStyle}
              >
                <img src={instagramLogo} alt="Instagram" width="90%" />
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
        {/* <Typography sx={{ mx: 3 }} color="white">
          Submit a request{" "}
        </Typography>
        <Typography sx={{ mx: 3 }} color="white">
          Log in
        </Typography>
        <Button
          sx={{ mx: 3, height: "40px" }}
          color="primary"
          variant="contained"
        >
          Subscribe
        </Button> */}
      </Toolbar>
    </Box>
  );
};

export default Navbar;
