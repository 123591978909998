import React from "react";
import {
  Fade,
  Grid,
  Link,
  Modal,
  Typography,
} from "@mui/material";



export const SendRequestButton = ({ closePopup }) => {
  return (
    <Grid>
      <Typography sx={{ mt: 2 }} variant="body2" component="div">
        Do you have any query? &nbsp;
        <Link
          onClick={closePopup}
          sx={{ height: "40px", cursor: "pointer" }}
          color="primary"
          variant="body2"
          component="span"
        >
          Submit a request
        </Link>
      </Typography>
    </Grid>
  );
};

const SendRequestModal = ({ isRequestModalOpen, handleRequestModalClose }) => {
  return (
    <Modal
      open={isRequestModalOpen}
      onClose={handleRequestModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isRequestModalOpen} timeout={500}>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title*/}
        <iframe
          id="zsfeedbackFrame"
          width="890"
          height="570"
          name="zsfeedbackFrame"
          scrolling="no"
          allowtransparency="false"
          frameborder="0"
          border="0"
          src="https://desk.zoho.in/support/fbw?formType=AdvancedWebForm&fbwId=edbsn707d318c12eb36739c0ed9d03f92aa1aa3e152e1c16fab9dac24e580345d3fec&xnQsjsdp=edbsn159abab0e82603286dd7c0c8a0536a63&mode=showNewWidget&displayType=iframe"
        ></iframe>
      </Fade>
    </Modal>
  );
};

export default SendRequestModal;
