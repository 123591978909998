import { CardContent, Typography, Card } from "@mui/material";
import React from "react";

const CustomCard = ({ icon, title, handleOpen, id }) => {
  return (
    <Card
      onClick={() => handleOpen(id, "SECTION")}
      sx={{
        width:"100%",
        height: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        ":hover": {
          cursor: "pointer",
          bgcolor: "whitesmoke",
          border: "2px solid #dc3545",
        },
      }}
      elevation={24}
    >
      <CardContent>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
